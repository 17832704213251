:root {
  --body-background-color: #FFFFFF !important;
}

@font-face {
  font-family: 'Avarta CY';
  src: url('assets/AvertaCY-Regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Avarta CY', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--body-background-color) !important;
  min-height: 100vh;
  overscroll-behavior-y: none;
}

:root {
  --primary-rgb: rgb(220, 20, 60, 0.4);
  --input-field: #9C9797;
  --primary-color: #0061AF;
  --card-color:#29292B;
  --primary-rgb: rgb(220, 20, 60, 0.4);
  --secondary-color: #FCAF17;
  --tertiary-color:#8B5A0F;
--footer-color:#666676;
--underline:#4F76AE;
  --list-color: #ECF9FF;
  --light-bg: #C7C7C7;
  --secondary-gradient: linear-gradient(121.52deg, #5d7f9e 0%, #003566 77.49%);
  --secondary-rgb: 37, 100, 184;
  --third-rgb: 34, 34, 34;
  --para-text: #D9D9D9;
  --white: #ffffff;
  --cards-bg:#FFFFFF;
  --input-bg: #F4F4F4;
  --grey-color: #525371,
  --white-rgb: 255, 255, 255;
  --black: #000000;
  --tabcolor:#343333;
  --sub-title: #9C9797;
  --table-id: "#D3E0F0",
  --green: #0e8749;
  --card: #888888;
  --card-secondary: #003E6B;
  --galileo: #ffffff;
  --flyhub: #4169e1;
  --mateblack: #222222;
  --tomato-color: #ED1C24;
  --gradient1: linear-gradient(121.52deg, #000428 0%, #004e92 77.49%);
  --gradient2: linear-gradient(121.52deg, #dc143c 0%, #f45c43 77.49%);
  --bgcolor: #ebf0f4;
  --bordercolor: #d9d9d9;
  --button-color: #2C74B3,
}

body::-webkit-scrollbar {
  display: none;
}

/******************************* progressbar css **************************************/
section {
  height: 1000px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.progress-section {
  position: fixed;
  left: 40px;
  top: 10%;
  width: 60px;
  height: 80%;
  display: flex;
  justify-content: space-between;
  will-change: transform;
  transition: .3s ease-out;
}

.progress-bar-wrap {
  position: relative;
  width: 2px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #4F575E;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 100px;
  background-color: var(--secondary-color);
  
}


/******************************* custom slider css **************************************/
.carousel {
  position: relative;
}

.carousel .carousel-inner .item {
  padding-bottom: 0;
  display: none;
}

.carousel .carousel-inner .item.active {
  display: block;
}

.c-controls {
  position: absolute;
  margin: 0;
  left: 70px;
  background-color: red;
  border: 1px solid #e5e5e5;
  color: var(--primary-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 5px 0 0;
  z-index: 2;
  line-height: 1;
}

.c-controls.next {
  right: 60px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
}

.c-controls.prev {
  left: 0px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
}

.c-controls:hover,
.tab-nav>li.active>a {
  color: var(--primary-color);
  border: none;
}


.c-controls:hover,
.tab-nav>li.active>a {
  background-color: var(--secondary-color);
  background-image: none;
  color:var(--mateblack) ;
}


/******************************* sidebar css **************************************/

.menu-lists{
  font-size: 65px;
}

.social-lists{
  font-size: 26px;
}

.menu-items{
  padding-bottom: 20px;
  color: var(--mateblack);
}

@media only screen and (max-width:600px){
.menu-items{
  padding-bottom: 8px;
  color: var(--mateblack);
}
}

@media only screen and (max-width:1600px){
.menu-lists{
  font-size: 55px;
}
}

@media only screen and (max-width:1024px){
.menu-lists{
  font-size: 45px;
}
.social-lists{
  font-size: 22px;
}
}

@media only screen and (max-width:1440px){
.menu-lists{
  font-size: 42px;
}
.social-lists{
  font-size: 22px;
}
}


/* .social-items{

} */


@media only screen and (max-width:600px){
 .social-lists {
  font-size: 20px;
}
}

@media only screen and (max-width:600px){
 .menu-lists {
  font-size: 35px;
}
}

/******************************* hero css **************************************/

.banner-container {
  height: 100vh;
  display: flex;
  /* width: 80%; */
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width:600px){
  .banner-container {
  height: 90vh;
  display: flex;
  /* width: 80%; */
  margin: 0 auto;
  position: relative;
}
}

@media only screen and (max-width:900px){
  .banner-container {
  height: 90vh;
  display: flex;
  /* width: 80%; */
  margin: 0 auto;
  position: relative;
}
}



.banner-container .banner-inner {
  /* width: 100%; */
  margin: auto;
  position: absolute;
  top: 25%;
  left: 0%;
}

@media only screen and (max-width: 600px) {
  .banner-container .banner-inner {
    /* change will be here */
    top: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .banner-container .banner-inner {
    /* change will be here */
    top: 15%;
  }
}


.banner-container .banner-inner .banner-items-container {
  display: flex;
  align-items: flex-end;
}

/* for small devices */
@media (max-width: 600px) {
  .banner-container .banner-inner .banner-items-container {
    display: block;
  }
}

/* for small devices */
@media (max-width: 900px) {
  .banner-container .banner-inner .banner-items-container {
    display: block;
    color: red;
  }
}


/* .banner-container .banner-inner .banner-items-container .banner-item p {
  font-size: 25px;
} */

.banner-container .banner-inner .banner-items-container .banner-item:hover {
  mix-blend-mode: exclusion;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: white;
}

.banner-container .banner-inner .banner-items-container .banner-item h1 {
  padding: 0 10px 0 0;
  font-size: 120px;
  margin: 0;
  font-weight: normal;
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #0061AF;
  cursor: pointer;
  z-index: 10;
}

.banner-container .banner-inner .banner-items-container .banner-item h1:hover {
  mix-blend-mode: exclusion;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: white;
}

.banner-container .banner-inner .banner-items-container .banner-item:last-child {
  margin-right: 0;
}



@media only screen and (max-width: 1600px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1{
    font-size: 110px;
  }
}

@media only screen and (max-width: 1440px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1{
    font-size: 100px;
  }

}

@media only screen and (max-width: 1024px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1 {
    font-size: 72px;
  }
}

@media only screen and (max-width: 768px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1 {
    font-size: 85px;
  }
}

@media only screen and (max-width: 428px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 320px) {
  .banner-container .banner-inner .banner-items-container .banner-item h1 {
    font-size: 65px;
  }
}


/* .banner-inner .banner-items-container .banner-item .vision-text {
  font-size: 25px;
  padding-left: 5px;
  margin-bottom: 10px;
} */


/******************************* services css **************************************/

.service-text{
  font-size: 50px;
  padding-bottom: 0px;
}


@media only screen and (max-width: 600px) {
  .service-text {
    font-size: 30px;
  }
}

.service-button {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .service-button {
    justify-content: center;
  }
}

/******************************* Footes css **************************************/

.footer-ul{
  list-style: none;
  font-size: 17px;
}

@media only screen and (max-width:600px){
  .footer-ul{
      font-size: 14px;
  }
}

.footer-list{
  display: flex;
  align-items: center;
  padding-top: 10px;
}


/******************************* react slick slider css **************************************/

/* service slider */

.slick-prev:before {
  content: "←";
  color: var(--primary-color) !important;
  font-size: 40px !important;
  z-index: 999 !important;
}

.slick-prev:hover:before {
  color: var(--secondary-color) !important;
}

 .slick-next:before {
  content: "→";
  color: var(--primary-color) !important;
  font-size: 40px !important;
}

.slick-next:hover:before {
  color: var(--secondary-color) !important;
}

.slider-button{
  position: relative;
}

.slick-next {
  position: absolute;
  top: -80px;
  right: 0 !important;
  display: none !;
}

.slick-prev { 
  position: absolute;
  top: -80px;
  left: auto;
  right: 50px !important;
  z-index: 999 !important;
}

 .slick-next {
  position: absolute;
  top: -80px;
  right: 0 !important;
  display: none !;

}

/* project slider */

.project-page .slick-prev:before {
  content: "←";
  color: var(--primary-color) !important;
  font-size: 40px !important;
  z-index: 999 !important;
}

.project-page .slick-prev:hover:before {
  color: var(--secondary-color) !important;
}

.project-page .slick-next:before {
  content: "→";
  color: var(--primary-color) !important;
  font-size: 40px !important;
}

.project-page .slick-next:hover:before {
  color: var(--secondary-color) !important;
}

.project-page .slider-button{
  position: relative;
}

.project-page .slider-button .slick-next {
  position: absolute;
  top: 550px;
  left: 50px !important;
  display: none !;
}

.project-page .slider-button .slick-prev { 
  position: absolute;
  top: 550px;
  left: 0px !important;
  right: 50px !important;
  z-index: 999 !important;
}

.project-page.check.slider-button .slick-next {
  position: absolute;
  top: -150px;
  right: 0 !important;
  display: none !;
}

/* career slider */

.career-page .slick-prev:before {
  content: "←";
  color: var(--primary-color) !important;
  font-size: 40px !important;
  z-index: 999 !important;
}

.career-page .slick-prev:hover:before {
  color: var(--secondary-color) !important;
}

.career-page .slick-next:before {
  content: "→";
  color: var(--primary-color) !important;
  font-size: 40px !important;
}

.career-page .slick-next:hover:before {
  color: var(--secondary-color) !important;
}

.career-page .slider-button{
  position: relative;
}

.career-page .slider-button .slick-next {
  position: absolute;
  top: -50px;
  right: 0 !important;
  display: none !;
}

.career-page .slider-button .slick-prev { 
  position: absolute;
  top: -50px;
  left: auto;
  right: 50px !important;
  z-index: 999 !important;
}

.career-page .check.slider-button .slick-next {
  position: absolute;
  top: -150px;
  right: 0 !important;
  display: none !;
}

/* message slider */

.message-page .slick-prev:before {
  content: "←";
  color: var(--primary-color) !important;
  font-size: 40px !important;
  z-index: 999 !important;
}

.message-page .slick-prev:hover:before {
  color: var(--secondary-color) !important;
}

.message-page .slick-next:before {
  content: "→";
  color: var(--primary-color) !important;
  font-size: 40px !important;
}

.message-page .slick-next:hover:before {
  color: var(--secondary-color) !important;
}

.message-page .slider-button{
  position: relative;
}

.message-page  .slider-button .slick-next {
  position: absolute;
  right: 45px !important;
  display: none !;
}

.message-page  .slider-button .slick-prev { 
  position: absolute;
  left: 25px !important;
  z-index: 999 !important;
}

.message-page .check.slider-button .slick-next {
  position: absolute;
  right: 0 !important;
  display: none !;
}


/******************************* others css **************************************/

.contactBtn:hover{
border-bottom: 1px solid var(--secondary-color);
transition: .5s;
}

.MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 10rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

.container {
  position: relative;
}


.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: var(--primary-color);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
   z-index: 2;
}

.container:hover .overlay {
  bottom: 0;
  height: 100%;
}

.container:hover .overlay-2 {
  top: 0;
  height: 100%;
}

.overlay-2 {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--secondary-color);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
}



.company-img{
  width: 280px;
}

@media only screen and (max-width:600px){
  .compnay-img{
      width: 150px;
      height: 100px;
  }
}


/******************************* best product ***********************************************/

.product-list {
  padding: 10px 12px 10px 12px;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 13px;
  height: 45px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

.un-product-list{
  padding: 10px 12px 0px 12px;
  background-color: #343333;
  color: var(--white);
  height: 40px;
  margin-top: 10px;
  font-size: 13px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

.day-un-product-list{
  padding: 10px 12px 0px 12px;
  background-color: #F0F0F0;
  color: var(--black);
  height: 40px;
  margin-top: 10px;
  font-size: 13px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

.best-list{
  display: flex;
  gap: 5px;
  align-items: center;
}


/******************************************** Erropage **************************************/

.settingImages{
  position: relative;
    width: 95vw;
    height: 70vh;
    margin-top: 100px;
    
}


.settingMidium{
    width: 280px;
    height: 280px;
    position: absolute;
    right: 10rem;
    bottom: 3.8rem;
    animation: midiumRotate linear infinite 1.8s;
    animation-delay: .7s;
}
@keyframes midiumRotate{
    0%{
        transform: rotate(360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}
.settingSmall{
    width: 200px;
    height: 200px;
    position: absolute;
    right: 6.5rem;
    bottom: 20.9rem;
    animation: smallRotate linear infinite 1.2s;
    animation-delay: .8s;
}
@keyframes smallRotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.settingBig{
    width: 390px;
    height: 390px;
    position: absolute;
    right: 19.5rem;
    bottom: 19.5rem;
    animation: bigRotate linear infinite 3.1s;
    animation-delay: 1s;
}
@keyframes bigRotate{
    0%{
        transform: rotate(360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

/* .css-1d48qp6-MuiFormLabel-root-MuiInputLabel-root {

  font-size: 20px !important;
} */


